import { Component } from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
// import { isMobile } from "react-device-detect";
import { NavInfo } from "../../shared/NavConst";
import { FiMenu, FiX } from "react-icons/fi";
import { IoIosArrowDown } from "react-icons/io"
import { ReactComponent as HotsauceLogoIcon } from "../../styles/img/Icons/hotsauce-logo.svg";
import hotsauceLogo from "../../styles/img/Logo.png";
import "../../styles/Nav.css";
class Nav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subNavOpen: false,
      subNavParent: "",
      subNavInfo: null,
      mobileMenuOpen: false,
      mobileSubNavOpen: false,
      navCurrentPath: "/",
      navBtnClick: false,
      windowWidth: window.innerWidth,
    };
  }

  renderSubNav = (parent, groupInfo) => {
    const { subNavOpen, navBtnClick, mobileMenuOpen, mobileSubNavOpen } = this.state;
    return (
      <>
        <div className="sub-nav-background" id="sub-nav">
          <div className={`sub-nav-btn-group ${navBtnClick && subNavOpen ? "click" : ""}`}>
            {groupInfo.map((item) => {
              return (
                <HashLink key={item.Title} smooth="true" to={parent + item.AnchorHash}>
                  <Button className="sub-nav-btn fontsize-s"
                    onClick={() => {
                      this.setState({ subNavOpen: false, mobileMenuOpen: false });
                    }}>
                    {item.Title}
                  </Button>
                </HashLink>
              );
            })}
          </div>
        </div>
        {mobileMenuOpen && mobileSubNavOpen ?
          <div className="mobile-sub-nav-background" id="sub-nav">
            <div className={`mobile-sub-nav-btn-group ${navBtnClick && mobileSubNavOpen ? "click" : ""}`}>
              {groupInfo.map((item) => {
                return (
                  <HashLink key={item.Title} smooth="true" to={parent + item.AnchorHash}>
                    <Button className="mobile-menu-btn fontsize-s"
                      onClick={() => {
                        this.setState({ subNavOpen: false, mobileMenuOpen: false });
                      }}>
                      {item.Title}
                    </Button>
                  </HashLink>
                );
              })}
            </div>
          </div>
          : null}
      </>)
  };


  render() {
    const {
      subNavOpen,
      subNavParent,
      subNavInfo,
      navCurrentPath,
      navBtnClick,
      mobileMenuOpen,
      mobileSubNavOpen
    } = this.state;
    return (
      <div className="nav-background">
        <div className="nav-bar">
          <div className="hotsauce-logo">
            <Link to="/">
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip className="home-hint">Home</Tooltip>}>
                <Button
                  className="logo-btn"
                  onClick={() => { this.setState({ subNavOpen: false, navClick: "/" }) }}>
                  <HotsauceLogoIcon
                    className="nav-logo"
                    src={hotsauceLogo}
                    alt="HotSauce Logo" />
                </Button>
              </OverlayTrigger>
            </Link>
          </div>
          <div className="nav-btn-group">
            {NavInfo.map((info) => {
              return (
                <Link key={info.Link} to={!info.SubNavOpen ? info.Link : window.location.pathname}>
                  <Button
                    className={`nav-btn fontsize-m
                    ${navBtnClick && navCurrentPath === info.Link ? "nav-btn-click" : ""} `}
                    onClick={() => {
                      info.SubNavInfo == null ?
                        this.setState({
                          subNavOpen: info.SubNavOpen,
                          navBtnClick: true,
                          navCurrentPath: info.Link
                        }) :
                        this.setState({
                          subNavOpen: !this.state.subNavOpen,
                          subNavParent: info.Link,
                          subNavInfo: info.SubNavInfo,
                          navBtnClick: true,
                          navCurrentPath: info.Link,
                        });
                    }}>
                    {info.Name}
                  </Button>
                </Link>
              )
            })}
          </div>
        </div>
        <div className="nav-bar-mobile">
          <Link to="/">
            <Button
              className="mobile-home-btn mobile-nav-btn"
              onClick={() => { this.setState({ mobileMenuOpen: false, mobileSubNavOpen: false }) }}>
              <HotsauceLogoIcon
                className="nav-logo"
                src={hotsauceLogo}
                alt="HotSauce Logo" />
            </Button>
          </Link>
          <Button className="menu-btn mobile-nav-btn"
            onClick={() => { this.setState({ mobileMenuOpen: !mobileMenuOpen, mobileSubNavOpen: false }) }}>
            {mobileMenuOpen ?
              <FiX className="menu-icon" /> :
              <FiMenu className="menu-icon" />}
          </Button>
        </div>
        {mobileMenuOpen ? (
          <div className="mobile-menu-btn-group">
            {NavInfo.map((info) => {
              return (
                <>
                  <Link key={info.Link} to={info.SubNavOpen ? window.location.pathname : info.Link}>
                    <Button
                      className="mobile-menu-btn"
                      onClick={() => {
                        info.SubNavInfo == null ?
                          this.setState({
                            mobileSubNavOpen: info.SubNavOpen,
                            mobileMenuOpen: false,
                          }) :
                          this.setState({
                            mobileSubNavOpen: !mobileSubNavOpen,
                            subNavParent: info.Link,
                            subNavInfo: info.SubNavInfo,
                            mobileMenuOpen: true,
                          });
                      }}>
                      <span className="mobile-menu-name fontsize-m">{info.Name}</span>
                      {info.SubNavInfo != null ?
                        <IoIosArrowDown className="sub-nav-open-icon" /> :
                        <div className="sub-nav-open-icon" />}
                    </Button>
                  </Link>
                  {mobileSubNavOpen && subNavParent === info.Link ?
                    this.renderSubNav(subNavParent, subNavInfo) : null}
                </>
              )
            })}
          </div>
        ) : null}
        {subNavOpen && !mobileSubNavOpen ? this.renderSubNav(subNavParent, subNavInfo) : null}
      </div>
    );
  }
}

export default withRouter(Nav);